/**
 * @package     Wordpress.Site
 * @subpackage  Templates.thanh2t
 *
 * @copyright   Copyright (C) 2020 thanh2t. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */

import(/* webpackPrefetch: true */ '../utils/header.js');
//Load fontface
import(/* webpackChunkName: "fontfaceobserver" */ 'fontfaceobserver').then((module) => {
  const FontFaceObserver = module.default;

  const fontOpenSans = new FontFaceObserver('OpenSans');
  const fontOswald = new FontFaceObserver('Oswald');

  Promise.all([fontOpenSans.load(), fontOswald.load()]).then(function () {
    document.documentElement.style.setProperty('--bs-font-sans-serif', 'OpenSans, sans-serif');
    document.documentElement.style.setProperty('--bs-font-sans-serif-bold', 'Oswald, sans-serif');
  });
});

//loader
window.addEventListener('DOMContentLoaded', function (event) {
  document.querySelector('.loading-first').classList.add('hided');
});

//add class when scroll
window.addEventListener('scroll', () => {
  if (window.scrollY > 10) {
    document.querySelector('header').classList.add('affix');
  } else {
    document.querySelector('header').classList.remove('affix');
  }
});
